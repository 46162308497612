import React from 'react';
import { ResetPasswordFields } from '../../../toolympus/components/auth';
import { useResetPassword } from '../../../toolympus/hooks/auth';
import { PublicPageHarness, PublicPageLogoTitle } from '../../Common/PublicPageWrapper';

export const ResetPasswordContainer = () => {
  const data = useResetPassword("/api/restore-access/restore")
    return (
      <PublicPageHarness>
        <form onSubmit={e => { e.preventDefault(); data.restore(); }} className="panel">
          <PublicPageLogoTitle subtitle="Восстановление пароля" />
          <ResetPasswordFields data={data} />
        </form>
      </PublicPageHarness>
    );
}
