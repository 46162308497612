import React, { useState, ReactNode, useEffect } from 'react';
import { MediaFile } from './useMediaLib';
import { IconButton, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { ArrowLeft, ArrowRight, Close } from '@mui/icons-material';
import { MediaLibPickerDialog } from './MediaLibPickerDialog';
import { MediaLibPickerWrapper } from './MediaLibPicker';

interface Props {
    selectedUrls: string[] | null;
    selectFiles: (mf: MediaFile[] | null, urls: string[] | null) => void;
    label?: ReactNode;
    placeholder?: ReactNode;
    disabled?: boolean;
    noUpload?: boolean;
}

export const MediaLibMultiPicker = (props: Props) => {
    const { selectedUrls, selectFiles, label, disabled } = props;
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    const [shownUrl, setShownUrl] = useState<string | null>(null);

    const isEmpty = !selectedUrls || selectedUrls.length === 0;

    const toggleImage = (direction: -1 | 1) => {
        if(selectedUrls && selectedUrls.length > 1) {
            let currentIdx = selectedUrls.indexOf(shownUrl || "");
            if(currentIdx < 0) {
                setShownUrl(selectedUrls[0]);
            } else {
                currentIdx += direction;
                if(currentIdx < 0) {
                    currentIdx = selectedUrls.length - 1;
                } else if(currentIdx >= selectedUrls.length) {
                    currentIdx = 0;
                }
                setShownUrl(selectedUrls[currentIdx]);
            }
        }
    }

    useEffect(() => {
        setShownUrl(!selectedUrls || selectedUrls.length === 0 ? null : selectedUrls[0]);
    }, [selectedUrls, setShownUrl]);

    return (
        <>
            <MediaLibPickerWrapper onClick={() => !disabled && setIsDialogOpen(true)} className="medialib-picker">
                {label && <Typography variant="caption">{label}</Typography>}
                {shownUrl
                    ? <img src={shownUrl} alt="" className="image" />
                    : <div className="placeholder">{props.placeholder || (<Typography><FormattedMessage id="medialib.select" /></Typography>)}</div>}
                
                {!isEmpty && <IconButton className="clear" onClick={e => { e.stopPropagation(); selectFiles([], []); }}><Close /></IconButton>}
                {selectedUrls && selectedUrls.length > 1 && <>
                    <IconButton className="prev-image" key="prev-image" size="small" onClick={(e) => { e.stopPropagation(); toggleImage(-1); }}><ArrowLeft /></IconButton>
                    <IconButton className="next-image" key="next-image" size="small" onClick={(e) => { e.stopPropagation(); toggleImage(+1); }}><ArrowRight /></IconButton>
                </>}
            </MediaLibPickerWrapper>
            <MediaLibPickerDialog
                isOpen={isDialogOpen}
                close={() => setIsDialogOpen(false)}
                selectedUrls={selectedUrls || []}
                selectFile={(f,url) => selectFiles(null, [...(selectedUrls || []), url])}
                unselectFile={(f,url) => selectFiles(null, (selectedUrls || []).filter(u => u !== url))}
                noUpload={props.noUpload}
                />
        </>
    );
}

