import { useNewItem } from "../../toolympus/api/useNewItem";
import { useCrudUnpagedList } from "../../toolympus/api/useSimpleCrud";
import { useSchema } from "../../toolympus/hooks/useSchema"
import { Product } from "./typings";

export const useProductList = () => {
    const data = useCrudUnpagedList<Product>("/api/settings/product", {
        defaultView: "",
    });

    const newItem = useNewItem<Pick<Product, "title" | "slug">, Product>("/api/settings/product", { title: "", slug: "" });

    const create = () => {
        newItem.startEditing();
        return Promise.resolve({ title: "", slug: ""} as Product);
    }

    const { product: schema } = useSchema();

    return {
        ...data,
        schema,
        create,
        newItem,
    }
}
