import React from 'react';
import { EditItemProps, NewItemProps } from '../../toolympus/api/useNewItem';
import { Invoice, NewInvoice } from './useInvoiceList';
import { Schema } from '../../toolympus/hooks/useSchema';
import { Dialog, FormGrid, useCopyText } from '../../toolympus/components/primitives';
import { Button, IconButton, InputAdornment } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { OrderStatus } from '../Orders/OrderStatus';
import { FileCopyOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';

interface Props {
  data: EditItemProps<Invoice>;
  schema: Schema;
  refreshPaymentStatus: (invoice: Invoice) => Promise<Invoice>;
}

export const EditInvoicePopup = (props: Props) => {
  const { data, schema, refreshPaymentStatus } = props;
  const copyText = useCopyText();

  const copyAdornment = (v: string | undefined) => ({ endAdornment: 
    <InputAdornment position="end"><IconButton size="small" onClick={() => copyText(v || "")}><FileCopyOutlined /></IconButton></InputAdornment>
  });

  const { enqueueSnackbar } = useSnackbar();

  return (
    <Dialog
      isOpen={data.isEditing}
      close={() => data.cancel()}
      dialogTitle="Счет"
      noFullscreen
      titleActions={<>
        <Button
          size="small"
          onClick={() => data.item && refreshPaymentStatus(data.item).then(() => enqueueSnackbar("Статус обновлен", { variant: "success" }))}>
          обновить статус платежа
        </Button>
        <OrderStatus order={{ status: data.item?.payment_status || "new" }} />
      </>}
      actions={<>
        <Button onClick={() => data.cancel()}><FormattedMessage id="common.cancel" /></Button>
        <Button onClick={() => data.save()} color="primary" variant="contained"><FormattedMessage id="common.save" /></Button>
      </>}>
      
      {data.item && <>
        <FormGrid columns="1fr 1fr" noMargin>
          <FormControlsForFields
            data={data.item}
            onChange={(o,c) => data.update(c)}
            schema={schema}
            fields={[
              ["_id", { disabled: true, controlProps: copyAdornment(`${data.item?._id}`) }],
              ["created_datetime", { disabled: true, utcToLocal: true }],
            ]}
            />
        </FormGrid>

        <FormGrid columns="1fr">
          <FormControlsForFields
            data={data.item}
            onChange={(o,c) => data.update(c)}
            schema={schema}
            fields={[
              ["title", { disabled: true, controlProps: copyAdornment(data.item?.title) }],
              ["comment", { autoRows: true }],
              ["customer_name", { disabled: true, controlProps: copyAdornment(data.item?.customer_name) }],
            ]}
            />
        </FormGrid>

        <FormGrid columns="1fr 1fr" noMargin>
          <FormControlsForFields
            data={data.item}
            onChange={(o,c) => data.update(c)}
            schema={schema}
            fields={[
              ["customer_email", { disabled: true, controlProps: copyAdornment(data.item?.customer_email) }],
              ["customer_phone", { disabled: true, controlProps: copyAdornment(data.item?.customer_phone) }],
              
              ["source_form_id", { disabled: true }],
              ["source_submission_id", { disabled: true, controlProps: copyAdornment(data.item?.source_submission_id) }],
              
              ["amount", { disabled: true }],
              ["amount_paid", { disabled: true }],
              
              ["payment_link", { disabled: true, controlProps: copyAdornment(data.item?.payment_link) }],
              ["payment_ref", { disabled: true, controlProps: copyAdornment(data.item?.payment_ref) }],
              
              ["payment_type", { disabled: true }],
              ["payment_notification_datetime", { disabled: true, utcToLocal: true }],
            ]}
            />
        </FormGrid>
      </>}
    </Dialog>
  )
}

interface CreateProps {
  data: NewItemProps<NewInvoice, Invoice>;
  schema: Schema;
}

export const CreateInvoicePopup = (props: CreateProps) => {
  const { data, schema } = props;
  return (
    <Dialog
      isOpen={data.isEditing}
      close={() => data.cancel()}
      dialogTitle="Новый счет"
      noFullscreen
      actions={<>
        <Button onClick={() => data.cancel()}><FormattedMessage id="common.cancel" /></Button>
        <Button onClick={() => data.save()} color="primary" variant="contained"><FormattedMessage id="common.create" /></Button>
      </>}>
      
      {data.item && <>
        <FormGrid noMargin columns="1fr">
          <FormControlsForFields
            data={data.item}
            onChange={(o,c) => data.update(c)}
            schema={schema}
            fields={[
              ["title"],
              ["comment", { autoRows: true }],
              ["customer_name"],
            ]}
            />
        </FormGrid>

        <FormGrid columns="1fr 1fr" noMargin>
          <FormControlsForFields
            data={data.item}
            onChange={(o,c) => data.update(c)}
            schema={schema}
            fields={[
              ["customer_email"],
              ["customer_phone"],
              ["amount"],
            ]}
            />
        </FormGrid>
      </>}
    </Dialog>
  )
}