import React from 'react';
import { useHistory } from 'react-router-dom';
import { SimpleDialog } from '../../toolympus/components/primitives/Dialogs';
import { FormGrid } from '../../toolympus/components/primitives/Forms';
import { SimpleList } from '../../toolympus/components/primitives/SimpleList';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { useProductList } from './useProductList';

interface Props {
    
}

export const ProductListPage = (props: Props) => {
    const data = useProductList();
    const history = useHistory();

    return (<>
        <SimpleList
            title="Товары"
            data={data}
            schema={data.schema}
            linkFields={["title", "slug"]}
            getLinkValue={r => `/products/${r.id}`}
            fields={[
                ["title"],
                ["slug"],
                ["price"],
            ]}
            />
        <SimpleDialog
            isOpen={data.newItem.isEditing}
            close={data.newItem.cancel}
            dialogTitle="Новый товар"
            save={() => data.newItem.save().then(p => history.push(`/products/${p.id}`))}>
            <FormGrid columns="1fr 1fr">
                {data.newItem.item && <FormControlsForFields
                    fields={[
                        ["title"],
                        ["slug"],
                    ]}
                    data={data.newItem.item}
                    onChange={(o,c) => data.newItem.update(c)}
                    schema={data.schema}
                    errors={data.newItem.errors}
                    />}
            </FormGrid>
        </SimpleDialog>
    </>);
}
