import React from 'react';
import { useInvoiceList } from './useInvoiceList';
import { PseudoLink2, Tooltip } from '../../toolympus/components/primitives';
import { useFields } from '../../toolympus/components/schemed';
import { OrderStatus } from '../Orders/OrderStatus';
import { CreateInvoicePopup, EditInvoicePopup } from './EditInvoicePopup';
import { ActionTriggerEditButton } from '../../toolympus/components/Actions';
import { IconButton } from '@mui/material';
import { GetAppOutlined } from '@mui/icons-material';
import { SimpleList2 } from '../../toolympus/components/primitives/SimpleList2';

interface Props {
  
}

export const InvoicesListPage = (props: Props) => {
  const data = useInvoiceList();

  const fields = useFields({
    defaultFields: [
      "_id",
      "title",
      "comment",
      "customer_name",
      "customer_email",
      "payment_status",
      "amount",
      "amount_paid",
      "created_datetime",
    ],
    schema: data.schema,
    extraSettings: {
      created_datetime: { utcToLocal: true },
      payment_notification_datetime: { utcToLocal: true },
    },
    storageKey: "_rac_invoices_fields",
  });

  return (<>
    <SimpleList2
      data={{
        data: data.data,
        setData: data.setData,
        filter: data.filter.filter,
        setFilter: data.filter.setFilter,
        view: data.view,
        setView: data.setView,
        reload: data.reload,
        isLoading: data.isLoading,
        create: () => data.create.startEditing(),
      }}
      schema={data.schema}
      title="Счета"
      tabs={[
        ["все", "all"],
        ["открытые", "unpaid"],
        ["оплаченные", "paid"],
      ]}

      onSearchSubmit={items => {
        if(items.length === 1) {
          data.edit.startEditing(items[0]);
        }
      }}

      headerActionsR={<>
        <ActionTriggerEditButton
          apiPath="/api/actions/trigger"
          triggers="shop.logic.invoices.invoices-actions/invoice-paid"
          buttonProps={{ color: "primary" }}
          />
        <Tooltip text="Выгрузить">
          <IconButton
            size="small"
            onClick={() => data.exportInvoices()}
            >
            <GetAppOutlined />
          </IconButton>
        </Tooltip>
      </>}
      
      fields={fields.activeFields}
      fieldSetings={fields}
      fieldElement={f => {
        switch(f) {
          case "_id":
          case "title":
            return (row,s,orig) => <PseudoLink2 onClick={() => data.edit.startEditing(row)}>{orig}</PseudoLink2>
          case "payment_status":
            return row => <OrderStatus order={{ status: row.payment_status || "new" }}  />
        }
      }}
      />

    <CreateInvoicePopup
      data={data.create}
      schema={data.schema}
      />
    <EditInvoicePopup
      data={data.edit}
      schema={data.schema}
      refreshPaymentStatus={data.refreshPaymentStatus}
      />
  </>);
}
