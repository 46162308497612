import React from 'react';
import { DocumentationRoutes } from './toolympus/components/Documentation';
import { MediaLibProvider } from './toolympus/components/medialib';

interface Props {
    
}

export const Documentation = (props: Props) => {
    return (
        <MediaLibProvider apiPath="/api/media" spaceId="docs" urlPrefix="/shopuploads">
            <DocumentationRoutes apiPath="/api/documentation" rootSlug="documentation" />
        </MediaLibProvider>
    );
}
