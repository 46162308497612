import { apiFetch } from "../../toolympus/api/core";
import { useNewItem } from "../../toolympus/api/useNewItem";
import { useCrudItem } from "../../toolympus/api/useSimpleCrud"
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { FieldType, Schema, useSchema } from "../../toolympus/hooks/useSchema";
import { PaymentType } from "../Settings/usePaymentTypesEdit";
import { Order } from "./typings";

const useRefreshPaymentStatus = (order: Order) => {
    const pt = useLoadedData<PaymentType>(`/api/settings/payment-types/${order.payment_type}`, {} as PaymentType, !!order.payment_type);

    const canRefresh = pt.data.mode === "sbp";

    const refresh = () => {
        if(canRefresh) {
            return apiFetch(`/api/admin/order/${order.id}/refresh-sbp-status`, "post");
        }
        return Promise.resolve();
    }

    return {
        canRefresh,
        refresh,
    }
}

export const useEditOrder = (id: string) => {
    const apiPath = `/api/admin/order/${id}`;
    const data = useCrudItem<Order>(apiPath, {
        defaultValue: {
            id: 0,
            email: "",
            phone: "",
            lastname: "",
            firstname: "",
            status: "new",
            products: [],
            payment_type: "",
        }, 
    });

    const { order: schema, order_product: detailsSchema } = useSchema();

    const deliveryInfoSchema: Schema = {
        country: { type: FieldType.text, label: "Страна" },
        city: { type: FieldType.text, label: "Город" },
        address: { type: FieldType.text, label: "Адрес" },
        comment: { type: FieldType.textlong, label: "Комментарий" },
    }

    const closeOrder = () => {
        apiFetch(apiPath, "put", { status: "closed" })
            .then(() => data.reload());
    }

    const sendMessage = useNewItem<{ message: string }, {}>(`${apiPath}/message`, { message: "" });

    const refreshPaymentStatus = useRefreshPaymentStatus(data.data);

    return {
        schema,
        detailsSchema,
        deliveryInfoSchema,
        closeOrder,
        sendMessage,
        refreshPaymentStatus: {
            ...refreshPaymentStatus,
            refresh: () => refreshPaymentStatus.refresh().then(() => data.reload()),
        },
        ...data,
    }
}

export type EditOrderData = ReturnType<typeof useEditOrder>;
