import React from 'react';
import styled from '@emotion/styled';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import { FormGrid } from '../../toolympus/components/primitives/Forms';
import { EditProductData } from './useProduct';
import { useEditArray } from '../../toolympus/hooks/useEditArray';

interface Props {
    data: EditProductData;
}

const Wrapper = styled.div`
`;

export const SizesEditor = (props: Props) => {
    const data = useEditArray({
        items: props.data.data.sizes || [],
        dflt: { code: "", label: "" },
        update: sizes => props.data.update({ sizes }),
    });

    return (
        <Wrapper>
            <Typography variant="h6">Размеры</Typography>

            <FormGrid columns="1fr 1fr max-content">
                {data.items.length > 0 && <React.Fragment key="caption">
                    <Typography key="caption_code" variant="caption">Код</Typography>
                    <Typography key="caption_label" variant="caption">Название</Typography>
                    <div key="caption_remove" />
                </React.Fragment>}

                {data.items.map((s,i) => (<React.Fragment key={`size_${i}`}>
                    <TextField
                        key="code"
                        value={s.code || ""}
                        onChange={e => data.update(i, { code: e.target.value })}
                        />
                    <TextField
                        key="label"
                        value={(props.data.locale === "ru" ? s.label : s.label_en) || ""}
                        onChange={e => data.update(i, { [props.data.locale === "ru" ? "label" : "label_en"]: e.target.value })}
                        />

                    <IconButton size="small" onClick={() => data.remove(i)}><DeleteOutlined /></IconButton>
                </React.Fragment>))}

                <Button size="small" color="primary" onClick={() => data.add()}>добавить размер</Button>
            </FormGrid>
        </Wrapper>
    );
}
