import React from 'react';
import { LoginFormFields } from '../../toolympus/components/auth';
import { useLoginForm } from '../../toolympus/hooks/auth';
import { PublicPageHarness, PublicPageLogoTitle } from '../Common/PublicPageWrapper';

export const LoginContainer = () => {
    const login = useLoginForm("/api/login", "/");
    return <PublicPageHarness>
      <form onSubmit={e => { e.preventDefault(); login.send(); }} className="panel">
        <PublicPageLogoTitle subtitle="Авторизация" />
        <LoginFormFields loginData={login} />  
      </form>
    </PublicPageHarness>;
}