import React from 'react';
import { RestorePasswordFields } from '../../../toolympus/components/auth';
import { PublicPageHarness, PublicPageLogoTitle } from '../../Common/PublicPageWrapper';
import { useRestorePassword } from '../../../toolympus/hooks/auth';

export const RestorePasswordContainer = () => {
  const data = useRestorePassword("/api/restore-access/reset");
  
  return (
    <PublicPageHarness>
      <form onSubmit={e => { e.preventDefault(); data.send(); }} className="panel">
        <PublicPageLogoTitle subtitle="Изменение пароля" />
        <RestorePasswordFields data={data} />
      </form>
    </PublicPageHarness>
  );
}
