import { EventOutlined, PeopleOutlined, PrintOutlined, SubjectOutlined, ViewCarouselOutlined } from '@mui/icons-material';
import React from 'react';
import { CMSMenu, CMSRecord, useLinkedRecordsEditorPlugin } from './toolympus/components/CMS';
import { MediaLibProvider } from './toolympus/components/medialib';
import { PowerEditorBaseProps, PowerEditorBase } from './toolympus/components/PowerDoc';
import { ColumnsPlugin } from './toolympus/components/PowerDoc/plugins/Columns';
import { CustomFieldsBlockPlugin } from './toolympus/components/PowerDoc/plugins/CustomFieldsBlock';
import { ExpanderPlugin } from './toolympus/components/PowerDoc/plugins/Expander';
import { useMediaFilesPlugin } from './toolympus/components/PowerDoc/plugins/MediaFiles';
import { PanelPlugin } from './toolympus/components/PowerDoc/plugins/Panel/PanelPlugin';
import { ScriptPlugin } from './toolympus/components/PowerDoc/plugins/Script';
import { TableElementType, TablesPlugin } from './toolympus/components/PowerDoc/plugins/Tables';
import { TilesPlugin } from './toolympus/components/PowerDoc/plugins/Tiles';
import { PowerEditorSettingsProvider } from './toolympus/components/PowerDoc/PowerEditorSettingsContext';
import { ConfigurableFormsSelectionProvider } from './toolympus/components/ConfigurableForms';
import { ConfigurableFormsEditorPlugin } from './toolympus/components/PowerDoc/plugins/ConfigurableForms';
import { ExtraCommandsPlugin } from './components/CMS';
import { PanelBlockElementType } from './toolympus/components/PowerDoc/plugins/Panel/PanelBlockElement';
import { LinkedRecordsBlockElementType } from './toolympus/components/CMS/LinkedRecordsEditorPlugin/types';
import { suggest, forBlock, setting, forAny } from './toolympus/components/PowerDoc/PowerEditorSettingsSuggestions';
import { TilesBlockElementType } from './toolympus/components/PowerDoc/plugins/Tiles/TilesBlockElement';

interface Props {
    
}

const Icons = {
    news: <PrintOutlined />,
    events: <EventOutlined />,
    banners: <ViewCarouselOutlined />,
    pages: <SubjectOutlined />,
    people: <PeopleOutlined />,
}

const SiteHost = "https://modernarbitration.ru";

const getSiteUrl = (record: Partial<CMSRecord> | null) => {
    const lang = record?.lang || "ru";

    switch(record?.record_type) {
        case "news":
            return `${SiteHost}/${lang}/news/${record.slug || ""}`;
        case "events":
            return `${SiteHost}/${lang}/events/${record.slug || ""}`;
        case "pages":
            const slug = (record?.slug === "index" ? "" : record?.slug || "").replace(/\./g, "/");
            return !slug && lang === "ru" ? SiteHost : `${SiteHost}/${lang}/${slug}`;
    }
}

const SettingsSuggestions = [
  suggest(forBlock(TableElementType),
    setting("_class", ["no_border"])),
  suggest(forBlock(TilesBlockElementType),
    setting("_class", ["plain", "vertical"])),
  suggest(forBlock(PanelBlockElementType),
    setting("display_type", ["custom-banner"])),
  suggest(forBlock(PanelBlockElementType),
    setting("carousel_mode", ["new", "legacy"])),
  suggest(forBlock(LinkedRecordsBlockElementType),
    setting("display_type", ["grid", "list", "list_detailed"])),
  suggest(forBlock(LinkedRecordsBlockElementType),
    setting("with_contacts", ["true", "false"])),
  suggest(forAny(),
    setting("_class")),
  suggest(forAny(),
    setting("display_type")),
  suggest(forAny(),
    setting("open", ["true", "false"])),
];

export const CMS = (props: Props) => {
    return (
        <MediaLibProvider apiPath="/api/media" spaceId="1" urlPrefix="/shopuploads">
          <ConfigurableFormsSelectionProvider apiPath="/api/form/config" formsPagesPath="/form">
            <PowerEditorSettingsProvider
                EditorComponent={RACEditorUnwrapped}
                settingsSuggestions={SettingsSuggestions}>

                <CMSMenu apiPath="/api/cms/" icons={Icons} configuration={{ getSiteUrl }} />

            </PowerEditorSettingsProvider>
          </ConfigurableFormsSelectionProvider>
        </MediaLibProvider>
    );
}


export const RACEditorUnwrapped = (props: PowerEditorBaseProps) => {
    const mediaFilesPlugin = useMediaFilesPlugin();
    const linkedRecordsPlugin = useLinkedRecordsEditorPlugin();

    return (
        <PowerEditorBase
            {...props}
            plugins={[
                mediaFilesPlugin,
                CustomFieldsBlockPlugin,
                TilesPlugin,
                ColumnsPlugin,
                PanelPlugin,
                ScriptPlugin,
                ExpanderPlugin,
                TablesPlugin,
                linkedRecordsPlugin,
                ConfigurableFormsEditorPlugin,
                ExtraCommandsPlugin,
            ]}
            />
    );
}
