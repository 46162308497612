import React from 'react';
import { FormWrapper } from '../../toolympus/components/primitives/Forms';
import { Button, Typography } from '@mui/material';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { ActionRow, OccupyFreeSpace } from '../../toolympus/components/primitives/ActionRow';
import { Add } from '@mui/icons-material';
import { DefaultLocaleSwitch } from '../../toolympus/components/LocalizedRecords';
import { DeliveryTypesEdit } from './useDeliveryTypesEdit';
import { ItemWrapper } from './Common.styles';


interface Props {
    data: DeliveryTypesEdit;
}

export const DeliveryTypesForm = (props: Props) => {
    const {
        items,
        changes,
        schema,
        addItem,
        localization,
    } = props.data;

    return (
        <FormWrapper elevation={0}>
            <ActionRow firstItemNoMargin itemMarginTop='0' lastItemMarginRight='0'>
                <OccupyFreeSpace />
                <DefaultLocaleSwitch locale={localization.locale} setLocale={localization.setLocale} />
            </ActionRow>
            {items.map((item, idx) => (
                <ItemWrapper key={item.pseudoKey} columns="1fr 100px 100px max-content">
                    <Typography variant="caption" className="caption">{idx+1}.</Typography>
                    <FormControlsForFields
                        data={item}
                        onChange={localization.updateItem}
                        schema={schema}
                        fields={[
                            ["title"],
                            ["code", { disabled: changes[item.pseudoKey]?.change !== "add" }],
                            ["sortorder"],
                            ["is_active"],
                            ["description", { wrapperStyle: { gridColumn: "span 4" }, autoRows: true }],
                            ["fields"],
                            ["price", { wrapperStyle: { gridColumn: "span 2" }}],
                        ]}
                        />
                </ItemWrapper>
            ))}

            <ActionRow>
                <OccupyFreeSpace />
                <Button color="primary" startIcon={<Add />} onClick={addItem}>добавить</Button>
            </ActionRow>
        </FormWrapper>
    );
}
