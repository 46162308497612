import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
} from '@mui/material';
import { Add, DeleteOutline, FilterList } from '@mui/icons-material';
import { FieldFilters } from './useFieldFilters';
import { Filter } from './typings';
import { FormControl as FormControlOur } from '../FormControl';
import { EmptyState } from '../../primitives/EmptyState';
import { Tooltip } from '../../primitives/Tooltip';
import { ExtraProps } from '../FormControlProps';
import { EditSavedFiltersDialog, FiltersToSaveDefaults, SavedFiltersSelection, SaveFiltersDialog, useSavedFilters } from './useSavedFilters';

interface Props {
    fieldFilters: FieldFilters;
}

interface DialogProps extends Props {
    isOpen: boolean;
    setIsOpen: (v: boolean) => void;
}

interface FilterProps {
    filter: Filter;
    fieldFilters: FieldFilters;
}

const FilterRow = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 2fr 6fr max-content;
    gap: 12px;
    align-items: flex-end;

    @media(max-width: 776px) {
        grid-template-columns: 1fr;
    }

    & > * {
        overflow: hidden;
    }
`;

const FieldControlExtraProps: ExtraProps = { autoComplete: true, controlProps: { autoFocus: true } };

const FilterEditor = ({ filter, fieldFilters}: FilterProps) => {
    const filterSchemas = fieldFilters.filterSchemas(filter);

    return (
        <FilterRow>
            <FormControlOur
                row={filter}
                field="field"
                schema={filterSchemas.field}
                onChange={(old, changes) => fieldFilters.update(old, changes)}
                extraProps={FieldControlExtraProps}
                />
            <FormControlOur
                row={filter}
                field="op"
                schema={filterSchemas.op}
                onChange={(old, changes) => fieldFilters.update(old, changes)}
            />
            {filterSchemas.needsValue ?
                <FormControlOur
                    row={filter}
                    field="value"
                    schema={filterSchemas.value}
                    onChange={(old, changes) => fieldFilters.update(old, changes)}
                />
                : <div />}
            <div>
                <IconButton size="small" onClick={() => fieldFilters.remove(filter)}>
                    <DeleteOutline />
                </IconButton>
            </div>
        </FilterRow>
    );
}

export const FieldFiltersPopup = ({ isOpen, setIsOpen, fieldFilters }: DialogProps) => {
    const { filters } = fieldFilters;
    const savedFilters = useSavedFilters(fieldFilters.userSettingsKey || "");

    useEffect(() => {
      if(isOpen) {
        savedFilters.ensureLoaded();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return (<>
        <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth={"md"} fullWidth >
            <DialogTitle>
                <Typography variant="h6">
                    <FormattedMessage id="fieldsfilters.title" />
                    <IconButton onClick={fieldFilters.add}>
                        <Add />
                    </IconButton>
                </Typography>
                
            </DialogTitle>
            <DialogContent>
                {!filters.length && <EmptyState text={<FormattedMessage id="fieldsfilters.emptyState" />} onClick={fieldFilters.add} />}
                {filters.map(f => <FilterEditor key={f.id} filter={f} fieldFilters={fieldFilters} />)}

                <SavedFiltersSelection
                  data={savedFilters}
                  selectFilter={f => fieldFilters.set(f.filters)}
                  />
            </DialogContent>
            <DialogActions>
                {!!filters.length && savedFilters.isAvailable &&
                  <Button onClick={() => savedFilters.saveFilters.startEditing({ ...FiltersToSaveDefaults, filters })}><FormattedMessage id="common.save" /></Button>}
                <Button onClick={() => fieldFilters.clear()}><FormattedMessage id="fieldssettings.reset" /></Button>
                <Button color="primary" onClick={() => setIsOpen(false)}><FormattedMessage id="common.close" /></Button>
            </DialogActions>
        </Dialog>

      {savedFilters.isAvailable && <SaveFiltersDialog data={savedFilters.saveFilters} isGlobalAvailable={savedFilters.canUpdateGlobal} />}
      {savedFilters.isAvailable && <EditSavedFiltersDialog data={savedFilters.editFilters} remove={savedFilters.removeFilters.run} />}
    </>);
}

interface ButtonProps extends Props {
    isOpen: boolean;
    setIsOpen: (v: boolean) => void;
}

export const FiltersButton = ({ fieldFilters, isOpen, setIsOpen }: ButtonProps) => {
    return (
        <Tooltip text_id="fieldsfilters.title">
            <IconButton
                size="small"
                onClick={() => setIsOpen(!isOpen)}
                color={fieldFilters.isActive ? 'primary' : 'default'}>
                <FilterList />
            </IconButton>
        </Tooltip>);
}

export const FieldFiltersPopupButton = (props: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return <>
        <FiltersButton {...props} isOpen={isOpen} setIsOpen={setIsOpen} />
        <FieldFiltersPopup isOpen={isOpen} setIsOpen={setIsOpen} {...props} />
    </>;
}

const FilterPanelWrapper = styled.div`
    display: flex;
    flex-flow: column;

    & > div:nth-child(1) {
        display: flex;
        flex-flow: row wrap;
    }

    & > div:nth-child(2) {
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
    }
`;

interface PanelProps extends Props {
    hideButtons?: boolean;
    noSavedFilters?: boolean;
}

export const FieldFiltersPanel = ({ fieldFilters, noSavedFilters, hideButtons }: PanelProps) => {
    const savedFilters = useSavedFilters(fieldFilters.userSettingsKey || "");

    useEffect(() => {
      if(!noSavedFilters) {
        savedFilters.ensureLoaded();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [noSavedFilters]);

    return <FilterPanelWrapper>
        <div>
            {fieldFilters.filters.map(f => <FilterEditor key={f.id} filter={f} fieldFilters={fieldFilters} />)}
            {!noSavedFilters &&
              <SavedFiltersSelection
                data={savedFilters}
                selectFilter={f => fieldFilters.set(f.filters)}
                />}
            {savedFilters.isAvailable && <SaveFiltersDialog data={savedFilters.saveFilters} isGlobalAvailable={savedFilters.canUpdateGlobal} />}
            {savedFilters.isAvailable && <EditSavedFiltersDialog data={savedFilters.editFilters} remove={savedFilters.removeFilters.run} />}
        </div>
        <div>
            {!hideButtons && <>
                {!!fieldFilters.filters.length && savedFilters.isAvailable &&
                    <Button onClick={() => savedFilters.saveFilters.startEditing({ ...FiltersToSaveDefaults, filters: fieldFilters.filters })}><FormattedMessage id="common.save" /></Button>}
                <Button onClick={() => fieldFilters.clear()}><FormattedMessage id="fieldssettings.reset" /></Button>
                <Button color="primary" onClick={fieldFilters.add}><FormattedMessage id="fieldsfilters.add" /></Button>
            </>}
        </div>
    </FilterPanelWrapper>;
}
