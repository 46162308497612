import { useSchema } from "../../toolympus/hooks/useSchema";
import { useLocalizedList } from "../../toolympus/components/LocalizedRecords";
import { useEditableList } from "../../toolympus/hooks/useEditableList";
import { apiFetch } from "../../toolympus/api/core";

export interface DeliveryType {
    code: string;
    is_active: boolean;
    sortorder: number;
    title: string;
    description?: string;
    price: string;
    fields: string[];
    translations: Record<string, string>;
}

const apiPath = "/api/settings/delivery-types";


export const useDeliveryTypesEdit = () => {
    const {
        items,
        changes,
        hasChanges,
        isLoading,
        addItem,
        updateItem,
        save,
    } = useEditableList<DeliveryType>({
        dflt: items => ({ code: "", is_active: false, title: "", translations: {}, price: "0.00", fields: [], sortorder: (Math.max(...items.map(i => i.sortorder)) || 0) + 1 }),
        idField: "code",
        getIdentity: pt => pt.code,
        api: {
            load: () => apiFetch(apiPath, "get"),
            create: (item) => apiFetch(apiPath, "post", item),
            update: (original, changes) => apiFetch(`${apiPath}/${original.code}`, "put", changes),
        },
    });

    const { delivery_type: schema } = useSchema();

    const itemsSorted = items.sort((a,b) => a.sortorder > b.sortorder ? 1 : -1);

    const itemsLocalized = useLocalizedList(itemsSorted, {
        defaultLocale: "ru",
        translatedFields: ["title", "description"],
        translationsField: "translations",
        updateItem: (o,c) => updateItem(o.pseudoKey, c),
    });

    return {
        items: itemsLocalized.items,
        schema,
        changes,
        hasChanges,
        save,
        isLoading,

        addItem,
        updateItem,
        localization: itemsLocalized,
    }
}

export type DeliveryTypesEdit = ReturnType<typeof useDeliveryTypesEdit>;