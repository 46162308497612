import React from 'react';
import styled from '@emotion/styled';
import { Button, IconButton, TextField, Typography } from '@mui/material';
import { DeleteOutlined } from '@mui/icons-material';
import { FormGrid } from '../../toolympus/components/primitives/Forms';
import { EditProductData } from './useProduct';
import { useEditArray } from '../../toolympus/hooks/useEditArray';

interface Props {
    data: EditProductData;
}

const Wrapper = styled.div`
`;

const ColorView = styled.div<{ color: string | undefined }>`
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background: ${props => props.color || "white"};
    box-shadow: 2px 2px 8px -2px #33333380;
`;

export const ColorsEditor = (props: Props) => {
    const data = useEditArray({
        items: props.data.data.colors || [],
        dflt: { code: "", label: "", color_code: "" },
        update: colors => props.data.update({ colors }),
    });

    return (
        <Wrapper>
            <Typography variant="h6">Цвета</Typography>

            <FormGrid columns="1fr 1fr 1fr max-content max-content" style={{ alignItems: "flex-end"}}>
                {data.items.length > 0 && <React.Fragment key="caption">
                    <Typography key="caption_code" variant="caption">Код</Typography>
                    <Typography key="caption_label" variant="caption">Название</Typography>
                    <Typography key="caption_color_code" variant="caption">Цвет</Typography>
                    <div key="caption_colorview" />
                    <div key="caption_remove" />
                </React.Fragment>}

                {data.items.map((s,i) => (<React.Fragment key={`color_${i}`}>
                    <TextField
                        key="code"
                        value={s.code || ""}
                        onChange={e => data.update(i, { code: e.target.value })}
                        />
                    <TextField
                        key="label"
                        value={(props.data.locale === "ru" ? s.label : s.label_en) || ""}
                        onChange={e => data.update(i, { [props.data.locale === "ru" ? "label" : "label_en"]: e.target.value })}
                        />
                    <TextField
                        key="color_code"
                        value={s.color_code || ""}
                        onChange={e => data.update(i, { color_code: e.target.value })}
                        />
                    <ColorView color={s.color_code} />

                    <IconButton size="small" onClick={() => data.remove(i)}><DeleteOutlined /></IconButton>
                </React.Fragment>))}

                <Button size="small" color="primary" onClick={() => data.add()}>добавить цвет</Button>
            </FormGrid>
        </Wrapper>
    );
}
