import { useState } from "react";
import { useTextFilter } from "../../toolympus/components/schemed/Filtering/useTextFilter";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { useSchema } from "../../toolympus/hooks/useSchema"
import { Order } from "./typings";

const LS_Key_ShowClosed = "rimashop_orders_show_closed";

export const useOrderList = () => {
    const data = useLoadedData<Order[]>("/api/admin/order", []);

    const { filter, setFilter, filterData } = useTextFilter<Order>(o => `${o.id} ${o.email} ${o.lastname} ${o.firstname}`)

    const { order: schema } = useSchema();

    const [showClosed, setShowClosedX] = useState<boolean>(localStorage.getItem(LS_Key_ShowClosed) === "true");

    const setShowClosed = (v: boolean) => {
        setShowClosedX(v);
        localStorage.setItem(LS_Key_ShowClosed, v ? "true" : "false");
    }


    return {
        ...data,
        data: filterData(data.data.filter(o => filter.length || showClosed || o.status !== "closed")),
        schema,
        filter,
        setFilter,
        showClosed,
        setShowClosed,
    }
}
