import React from "react";
import { Settings, PhotoAlbumOutlined, SettingsOutlined, ShoppingBasketOutlined, ShoppingCartOutlined, PrintOutlined, BathtubOutlined, AssignmentTurnedInOutlined, PaymentOutlined } from "@mui/icons-material";
import { Roles } from "./components/Auth";

import { MenuRoute } from "./toolympus/components/frame";
import { SystemSubmenu } from "./toolympus/components/Contests/SystemSubmenu";
import { MediaLibContainer } from "./toolympus/components/medialib/MediaLibForm";
import { SettingsPage } from "./components/Settings/SettingsPage";
import { ProductListPage } from "./components/Products/ProductListPage";
import { ProductEditPage } from "./components/Products/ProductEditPage";
import { OrderListPage } from "./components/Orders/OrderListPage";
import { OrderEditPage } from "./components/Orders/OrderEditPage";
import { SiteSettingsPage } from "./components/SiteSettings";
import { CMS } from "./CMS";
import { Documentation } from "./Documentation";
import { ConfigurableFormsRoutes } from "./toolympus/components/ConfigurableForms";
import { DefaultLanguage, LanguagesWithLabels } from "./components/Localization";
import { MediaLibProvider } from "./toolympus/components/medialib";
import { InvoicesListPage } from "./components/Invoices";

const LocalizationSettings = { defaultLocale: DefaultLanguage, locales: LanguagesWithLabels };

const InnerRoutes: MenuRoute[] = [
    {
        path: "/orders/:id",
        title: "Заказы",
        icon: <ShoppingBasketOutlined />,
        component: OrderEditPage,
        hidden: true,
    },
    {
        path: "/orders",
        title: "Заказы",
        icon: <ShoppingCartOutlined />,
        component: OrderListPage,
        alsoActivateForPath: p => p.startsWith("/orders/"),
    },
    {
        path: "/products/:id",
        title: "Товары",
        icon: <ShoppingBasketOutlined />,
        component: ProductEditPage,
        hidden: true,
    },
    {
        path: "/products",
        title: "Товары",
        icon: <ShoppingBasketOutlined />,
        component: ProductListPage,
        alsoActivateForPath: p => p.startsWith("/products/"),
    },
    {
        path: "/invoices",
        title: "Счета",
        icon: <PaymentOutlined />,
        component: InvoicesListPage,
        alsoActivateForPath: p => p.startsWith("/invoices/"),
    },


    
    {
        path: "/cms",
        title: "CMS",
        icon: <PrintOutlined />,
        component: CMS,
        alsoActivateForPath: p => p.startsWith("/cms/"),
    },

    {
        path: "/form",
        title: "Формы",
        icon: <AssignmentTurnedInOutlined />,
        component: () => <ConfigurableFormsRoutes apiPath="/api/form/config" localization={LocalizationSettings} />,
        alsoActivateForPath: p => p.startsWith("/form/"),
    },
    
    {
        path: "/documentation",
        title: "Документация",
        icon: <BathtubOutlined />,
        component: Documentation,
        alsoActivateForPath: p => p.startsWith("/documentation/"),
    },

    { 
        path: '/system',
        title: 'Конфигурация',
        icon: <Settings />,
        component: () => (
          <MediaLibProvider apiPath="/api/media" spaceId="1" urlPrefix="/shopuploads">
            <SystemSubmenu
                emails={{ apiPath: "/emails" }}
                notificationTasks={{ apiPath: "/api/notifications/task" }}
                users={{ userRoles: Roles, allowInvites: true, allowPaswordReset: true, allowPaswordChange: true }}
                apiTokens={{ apiPath: "/api/apitoken", roles: Roles }}
                dictionaries={{ apiPath: "/api/settings/dictionary" }}
                queryConsole={{}}
                robud={{}}
                messages={{}}
                extraItems={[
                    { key: "media", label: "Медиафайлы", icon: <PhotoAlbumOutlined />, items: [
                        { key: "media", label: "", component: () => <MediaLibContainer apiPath="/api/media" spaceId="1" urlPrefix="/shopuploads" /> }
                    ] },
                    { key: "settings", label: "Настройки магазина", icon: <SettingsOutlined />, items: [
                        { key: "settings", label: "", component: SettingsPage }
                    ] },
                    { key: "site-settings", label: "Настройки сайта", icon: <SettingsOutlined />, items: [
                        { key: "site-settings", label: "", component: SiteSettingsPage }
                    ] },
                ]}
            />
          </MediaLibProvider>),
    },
];

export default InnerRoutes;