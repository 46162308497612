import { useMemo, useState } from "react";
import { useLoadedData } from "../../toolympus/hooks/useLoadedData";
import { FieldType, Schema, mergeSchema, useSingleSchema } from "../../toolympus/hooks/useSchema";
import { useTextFilter } from "../../toolympus/components/schemed/Filtering/useTextFilter";
import { useEditItem2, useNewItem } from "../../toolympus/api/useNewItem";
import { apiFetch, downloadBuffer } from "../../toolympus/api/core";
import ExcelJS from "exceljs";
import { utc } from "../../toolympus/components/timezone";
import { DateTimeInternalFormat } from "../../toolympus/api/datetimeUtil";

export interface Invoice {
  _id?: number;
  title: string;
  comment?: string;
  customer_name?: string;
  customer_email?: string;
  customer_phone?: string;
  source_form_id?: string;
  source_submission_id?: string;
  payment_type?: string;
  payment_link?: string;
  payment_ref?: string;
  payment_status?: string;
  amount?: string;
  amount_paid?: string;
  payment_notification_datetime?: string;
  created_datetime?: string;
}


const exportInvoicesXLSX = (schema: Schema, invoices: Invoice[]) => {
  const workbook = new ExcelJS.Workbook();
  const sheet = workbook.addWorksheet("Счета");

  
  sheet.columns = [
    { header: schema._id.label as string || "", key: "_id" },
    { header: schema.created_datetime.label as string || "", key: "created_datetime" },
    { header: schema.payment_status.label as string || "", key: "payment_status" },
    { header: schema.title.label as string || "", key: "title" },
    { header: schema.comment.label as string || "", key: "comment" },
    { header: schema.customer_name.label as string || "", key: "customer_name" },
    { header: schema.customer_email.label as string || "", key: "customer_email" },
    { header: schema.customer_phone.label as string || "", key: "customer_phone" },
    { header: schema.amount.label as string || "", key: "amount" },
    { header: schema.amount_paid.label as string || "", key: "amount_paid" },
    { header: schema.payment_notification_datetime.label as string || "", key: "payment_notification_datetime" },
    { header: schema.source_form_id.label as string || "", key: "source_form_id" },
    { header: schema.source_submission_id.label as string || "", key: "source_submission_id" },
    { header: schema.payment_type.label as string || "", key: "payment_type" },
    { header: schema.payment_ref.label as string || "", key: "payment_ref" },
    { header: schema.payment_link.label as string || "", key: "payment_link" },
  ];

  invoices.forEach(invoice => {
      const { created_datetime, payment_notification_datetime, ...other } = invoice;
      sheet.addRow({
        ...other,
        created_datetime: utc.toLocal(created_datetime).format(DateTimeInternalFormat),
        payment_notification_datetime: payment_notification_datetime ?utc.toLocal(payment_notification_datetime).format(DateTimeInternalFormat) : "",
      })
  });

  workbook.xlsx
    .writeBuffer({ base64: true } as any)
    .then((xls64) => downloadBuffer(xls64, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", `счета${new Date().getFullYear()}_${(new Date().getMonth()+1)}_${new Date().getDate()}.xlsx`));

}



export type NewInvoice = Omit<Invoice, "_id">;

const ApiPath = "/api/invoice";

export const useInvoiceList = () => {
  const [view, setView] = useState<string>("all");
  const viewArg = view && view !== "all" ? `view=${view}` : "";
  const data = useLoadedData<Invoice[]>(`${ApiPath}?${viewArg}`, []);

  const filter = useTextFilter<Invoice>(i => `${i.title} ${i._id} ${i.comment} ${i.customer_name} ${i.customer_email}`)

  const { schema: schemaSrc } = useSingleSchema(`${ApiPath}/uiconfig`);
  const schema = useMemo(() => mergeSchema(
    schemaSrc,
    {
      comment: { type: FieldType.textlong },
      payment_type: { label: "Способ оплаты" },
    }
  ), [schemaSrc]);

  const create = useNewItem<NewInvoice, Invoice>(ApiPath, { title: "" });
  const edit = useEditItem2<Invoice>({
    getApiPath: i => `${ApiPath}/${i._id}`,
  });

  const refreshPaymentStatus = (invoice: Invoice) => {
    return apiFetch<Invoice>(`${ApiPath}/${invoice._id}/refresh-sbp-status`, "POST")
      .then(result => {
        data.reload();
        if(edit.isEditing && edit.item?._id === result._id) {
          edit.setItem(result);
        }
        return result;
      });
  }

  const filteredData = filter.filterData(data.data);

  const exportInvoices = () => {
    exportInvoicesXLSX(schema, filteredData)
  }

  return {
    ...data,
    data: filteredData,
    schema,
    view,
    setView,
    filter,
    
    create: {
      ...create,
      save: () => create.save().then(x => { data.reload(); return x; }),
    },
    edit: {
      ...edit,
      save: () => edit.save().then(x => { data.reload(); return x; }),
    },
    refreshPaymentStatus,
    exportInvoices,
  }
}
