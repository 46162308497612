import React, { useState } from 'react';
import styled from '@emotion/styled';
import {
    useHistory,
} from "react-router-dom";
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import InnerRoutes from './InnerRoutes';
import { clearApiToken, decodeToken, getApiToken } from './toolympus/api/core';
import { SchemaProvider } from './toolympus/hooks/useSchema';
import { AppWrapper, Sidebar, Menu, Routes, getRoutesToRender, AppContentSimple, DrawerPaperView } from './toolympus/components/frame/new';
import { DefaultUser, UserContext } from './toolympus/userContext/UserContext';
import { ArrowBackIos, ArrowForwardIos, ExitToApp } from '@mui/icons-material';
import { useLoginGuard } from './toolympus/hooks/auth/useLoginGuard';
import { useMenuCollapsed } from './toolympus/components/frame/useMenuState';
import LogoImg from './logo.png';
import { DictionariesProvider } from './toolympus/hooks/useDictionaries';
import { HistoryFavorites } from './toolympus/components/HistoryFavorites';
import { ActionsConfigProvider } from './toolympus/components/Actions/ActionsConfigProvider';


const SidebarPaper = styled(DrawerPaperView)`
    & .MuiDrawer-paper{
        color: ${props => props.theme.palette.background.paper};
        background-color: ${props => props.theme.palette.text.primary};
    }

    & .MuiSvgIcon-root {
        color: ${props => props.theme.palette.background.paper};
    }
`;

const Logo = styled.img<{ small?: boolean }>`
    width: ${props => props.small ? "60px" : "100%"};
    box-sizing: border-box;
    padding: ${props => props.small ? "2rem 0.5rem" : "2rem 1.5rem"};
`;
Logo.defaultProps = { src: LogoImg };

const AppRouter: React.FC = () => {
    const token = getApiToken();
    const history = useHistory();
    const [user, setUser] = useState<DefaultUser>(token ? decodeToken(token) as DefaultUser : { _id: '', roles: [], email: '' });

    const logout = () => {
        clearApiToken();
        history.push('/login');
    }

    useLoginGuard("/login", { isAuthenticated: !!user && user.roles.length > 0 });

    const menuState = useMenuCollapsed({ lsKeyPrefix: "__rima_shop", collapsedByDefault: true });

    const { routes, menuItems } = getRoutesToRender(InnerRoutes, user);

    const bottom = (
        <List>
            <ListItem button key='collapse' onClick={menuState.toggleCollapsed}>
                <ListItemIcon title={menuState.isCollapsed ? "развернуть меню" : "свернуть меню"}>
                    {menuState.isCollapsed ? <ArrowForwardIos /> : <ArrowBackIos />}
                </ListItemIcon>
            </ListItem>
            <ListItem button key='logout' onClick={logout}>
                <ListItemIcon><ExitToApp /></ListItemIcon>
                <ListItemText primary='Выход' />
            </ListItem>
        </List>
    )

    return (
        <UserContext.Provider value={{ user: user, setUser: setUser }}>
          <ActionsConfigProvider>
            <SchemaProvider>
                <DictionariesProvider apiPath="/api/settings/dictionary">
                    <HistoryFavorites lsKey="_rac_shop_histfavs">
                        <AppWrapper>
                                <Sidebar
                                    top={<Logo small={menuState.isCollapsed} />}
                                    menu={<Menu iconsOnly={menuState.isCollapsed} menuItems={menuItems} />}
                                    bottom={bottom}
                                    open={true}
                                    onClose={() => { }}
                                    drawerPaperProps={{ shadow: true }}
                                    drawerPaperComponent={SidebarPaper}
                                    width={menuState.isCollapsed ? 60 : undefined}
                                />
                            <AppContentSimple fit100Height>
                                <Routes routes={routes} notFoundRedirect="/system" />
                            </AppContentSimple>
                        </AppWrapper>
                    </HistoryFavorites>
                </DictionariesProvider>
            </SchemaProvider>
          </ActionsConfigProvider>
        </UserContext.Provider>
    );
}

export default AppRouter;

