import { useCrudItem } from "../../toolympus/api/useSimpleCrud";
import { useSchema } from "../../toolympus/hooks/useSchema";

interface Settings {
    orders_allowed: boolean;
    self_notification_email?: string;
    categories: string[];
    sbp: any;
}

export const useSettingsEdit = () => {
    const data = useCrudItem<Settings>("/api/settings/settings", { defaultValue: { orders_allowed: false, categories: [], sbp: {} }});
    const { settings: schema, settings_sbp: sbpSchema } = useSchema();

    return {
        ...data,
        schema,
        sbpSchema,
    }
}
