import { useState } from "react";

interface Config {
    lsKeyPrefix?: string;
    collapsedByDefault?: boolean;
}

export const useMenuCollapsed = (cfg?: Config) => {
    const lsk = `${cfg?.lsKeyPrefix || "__tooly"}__menu_collapsed`;
    const [isCollapsed, update] = useState<boolean>(localStorage.getItem(lsk) ? localStorage.getItem(lsk) === "t" : (cfg?.collapsedByDefault || false));

    const setIsCollapsed = (v: boolean) => {
        update(v);
        localStorage.setItem(lsk, v ? "t" : "f");
    };

    return {
        isCollapsed,
        setIsCollapsed,
        open: () => setIsCollapsed(false),
        close: () => setIsCollapsed(true),
        toggleCollapsed: () => setIsCollapsed(!isCollapsed),
    }
}
