import { Button, IconButton } from '@mui/material';
import { FileCopyOutlined, KeyboardBackspace, SaveOutlined } from '@mui/icons-material';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTrackHistory } from '../../toolympus/components/HistoryFavorites';
import { DefaultLocaleSwitch } from '../../toolympus/components/LocalizedRecords';
import { MediaLibPicker, MediaLibProvider } from '../../toolympus/components/medialib';
import { MediaLibMultiPicker } from '../../toolympus/components/medialib/MediaLibMultiPicker';
import { useSaveable } from '../../toolympus/components/primitives';
import { OccupyFreeSpace } from '../../toolympus/components/primitives/ActionRow';
import { Link } from '../../toolympus/components/primitives/Common.styles';
import { Form, FormGrid } from '../../toolympus/components/primitives/Forms';
import { TabPanel, TabsHeader, useTabsState } from '../../toolympus/components/primitives/Tabs';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { ColorsEditor } from './ColorsEditor';
import { SizesEditor } from './SizesEditor';
import { useEditProduct } from './useProduct';

interface Props {
    
}

export const ProductEditPage = (props: Props) => {
    const { id } = useParams<{ id: string }>();
    const data = useEditProduct(id);
    const history = useHistory();

    useSaveable(data);
    useTrackHistory(data.data.title ? data.data.title : undefined);

    const cloneProduct = () => {
        data.cloneProduct().then(p => history.push(`/products/${p.id}`));
    }

    const tabs = useTabsState([
        ["description", "Описание"],
        ["images", "Изображения и файлы"],
        ["colors-sizes", "Цвета и размеры"],
    ], "description", "tab");

    
    return (
        <MediaLibProvider apiPath="/api/media" spaceId="1" urlPrefix="/shopuploads">
            <Form
                title={<>
                    <Link to="/products">
                        <IconButton size="small"><KeyboardBackspace /></IconButton>
                    </Link>
                    Товар
                </>}
                headerItems={<>
                <OccupyFreeSpace />
                <Button size="small" startIcon={<FileCopyOutlined />} onClick={cloneProduct}>скопировать товар</Button>
                {data.hasChanges && <IconButton size="small" onClick={() => data.save()}><SaveOutlined /></IconButton>}
                <DefaultLocaleSwitch locale={data.locale} setLocale={data.setLocale} />
                </>}
                >
                <FormGrid columns="1fr 1fr 1fr 1fr">
                    <FormControlsForFields
                        data={data.data}
                        schema={data.schema}
                        onChange={(o,c) => data.update(c)}
                        fields={[
                            ["title", { wrapperStyle: { gridColumn: "span 2"}}],
                            ["slug"],
                            ["category"],
                            ["price", { disabled: data.data.is_free_downloadable }],
                            ["original_price", { visibleIf: p => !p.is_free_downloadable }],
                            ["discount", { visibleIf: p => !p.is_free_downloadable }],
                            ["is_available"],
                            ["is_free_downloadable"],
                        ]}
                    />
                </FormGrid>

                <TabsHeader state={tabs} />

                <TabPanel state={tabs.forTab("description")}>
                    <FormGrid columns="1fr">
                        <FormControlsForFields
                            data={data.data}
                            schema={data.schema}
                            onChange={(o,c) => data.update(c)}
                            fields={[
                                ["description"],
                                ["details"],
                                ["extra_details"],
                            ]}
                        />
                    </FormGrid>
                </TabPanel>

                <TabPanel state={tabs.forTab("images")}>
                    <FormGrid columns="1fr max-content 1fr" style={{ gridTemplateRows: "max-content 100px"}}>
                        <div key="1" />
                        <MediaLibMultiPicker
                            label={data.schema["images"].label}
                            selectedUrls={data.data.images || []}
                            selectFiles={(fs, urls) => data.update({ images: urls || [] })}
                            />
                        <div key="2" />

                        {data.data.is_free_downloadable && <>
                            <div key="1_f" />
                            <MediaLibPicker
                                showFilenameInsteadOfImage
                                label={data.schema["download_url"].label}
                                selectedUrl={data.data.download_url}
                                selectFile={(fs, url) => data.update({ download_url: url })}
                                />
                            <div key="2_f" />
                        </>}
                    </FormGrid>
                </TabPanel>

                <TabPanel state={tabs.forTab("colors-sizes")}>
                    <FormGrid columns="2fr 3fr">
                        <SizesEditor data={data} />
                        <ColorsEditor data={data} />
                    </FormGrid>
                </TabPanel>
            </Form>
        </MediaLibProvider>
    );
}
