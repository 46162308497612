import { createTheme } from '@mui/material/styles';
import { BaseMuiThemeComponentsSettings } from './toolympus/components/primitives/BaseThemeSettings';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#279dd5',
        },
        secondary: {
            main: '#e1232e',
        },
    },

    typography: {
      fontFamily: "Helvetica",
    },

    components: {
      ...BaseMuiThemeComponentsSettings,
    },
});
