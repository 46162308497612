import { useLocalizer } from "./useLocalizer";

interface Config<T> {
    defaultLocale: string;
    updateItem: (original: T, changes: Partial<T>) => void;
    translationsField: keyof T;
    translatedFields: (keyof T)[];
}

export const useLocalizedList = <T>(source: T[], config: Config<T>) => {
    const localizer = useLocalizer(config);

    const {
        locale,
        translate,
        setLocale,
        isDefaultLocale,
        updateItem,
    } = localizer;

    const items = isDefaultLocale ? source : source.map(translate);

    return {
        items,
        locale,
        setLocale,
        updateItem,
    };
}