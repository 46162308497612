import { useEffect, useState } from "react";


type WithPseudoKey<T> = T & { pseudoKey: string };
type Change<T> = Partial<T> & { change: "add" | "update" | "delete" };

interface Config<T> {
    getIdentity: (item: T) => string;
    dflt: T | ((items: T[]) => T);
    idField: keyof T;

    api: {
        load: () => Promise<T[]>,
        create: (record: T) => Promise<T>,
        update: (original: T, changes: Partial<T>) => Promise<T>,
    }
}

const genRandom = () => Math.floor(Math.random() * 100);




export interface EditableList<T> {
    items: WithPseudoKey<T>[];
    changes: Record<string, Change<T>>;
    hasChanges: boolean;
    save: () => Promise<void>;
    isLoading: boolean;

    addItem: () => void;
    updateItem: (pseudoKey: string, changes: Partial<T>) => void;
}


export const useEditableList = <T>(cfg: Config<T>): EditableList<T> => {
    const [loadeditems, setLoadeditems] = useState<T[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    const reload = () => {
        setIsLoading(true);
        cfg.api.load().then(x => {
            setLoadeditems(x);
            setIsLoading(false);
        })
        .catch(e => {
            setIsLoading(false);
            throw e;
        })
    };

    useEffect(() => {
        reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [items, setItems] = useState<WithPseudoKey<T>[]>([]);
    const [changes, setChanges] = useState<Record<string, Change<T>>>({});
    const hasChanges = Object.keys(changes).length > 0;

    useEffect(() => {
        const keyed = loadeditems.map(i => ({ ...i, pseudoKey: `${cfg.getIdentity(i)}_${genRandom()}`}))
        setItems(keyed);
        setChanges({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadeditems]);

    const addItem = () => {
        const pseudoKey = `new_${genRandom()}`
        const added = typeof cfg.dflt === "function" ? (cfg.dflt as ((items: T[]) => T))(items) : { ...cfg.dflt };
        setItems(items => [...items, { ...added, pseudoKey }]);
        setChanges(changes => ({ ...changes, [pseudoKey]: { ...added, change: "add" } }));
    }

    const updateItem = (pseudoKey: string, itemChanges: Partial<T>) => {
        const oldChange = changes[pseudoKey];
        if(oldChange && oldChange.change === "delete") {
            return;
        }

        const newChange: Change<T> = oldChange ? { ...oldChange, ...itemChanges } : { change: "update", ...itemChanges };

        setChanges(changes => ({ ...changes, [pseudoKey]: newChange }));
        setItems(items => items.map(item => item.pseudoKey === pseudoKey ? { ...item, ...itemChanges } : item));
    }

    const save = () => {
        setIsSaving(true);
        const operations = Object.entries(changes).map(([ pseudoKey, changes]) => {
            const data = { ...changes } as any;
            delete data.change;

            if(changes.change === "add") {
                return cfg.api.create(data);
            } else if(changes.change === "update") {
                delete data[cfg.idField];
                const original = items.find(it => it.pseudoKey === pseudoKey);
                if(original) {
                    return cfg.api.update(original, data);
                }
            }
            return Promise.resolve({} as T);
        });

        return Promise.all(operations)
            .then(() => {
                reload();
                setIsSaving(false);
            });
    }

    return {
        items,
        changes,
        hasChanges,
        save,
        isLoading: isLoading || isSaving,

        addItem,
        updateItem,
    }
}
