import React from 'react';
import { SignupFormFields } from '../../toolympus/components/auth';
import { SignupMode, useSignup } from '../../toolympus/hooks/auth';
import { PublicPageHarness, PublicPageLogoTitle } from '../Common/PublicPageWrapper';

export const SignupContainer = () => {
    const mode = SignupMode.Common;
    const signupData = useSignup({ signupApiPath: "/api/signup", mode: mode });

    return (
      <PublicPageHarness>
        <form onSubmit={e => { e.preventDefault(); signupData.send(); }} className="panel">
          <PublicPageLogoTitle subtitle="Регистрация" />
          <SignupFormFields mode={mode} signupData={signupData} />  
        </form>
      </PublicPageHarness>
    );
}
