import React, { PropsWithChildren, ReactNode, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Logo } from '.';
import { Typography } from '@mui/material';
import LogoImg from '../../logo.png';

const Wrapper = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    & img {
        width: 300px;
    }
`;

export const PublicPageWrapper = (props: { children?: ReactNode }) => {
    return <Wrapper>
        <Logo version={2} />
        {props.children}
    </Wrapper>
}


const RedStripBase = styled.img`
  position: fixed;
  height: 10%;
  width: 80%;
  object-fit: cover;
  object-position: center left;
`;


const RedStripX = styled(RedStripBase)<{ idx: number, widthAdd: number, transparent: boolean }>`
  left: ${props => props.idx % 2 === 0 ? 0 : "unset"};
  right: ${props => props.idx % 2 === 0 ? "unset" : 0};
  top: ${props => props.idx * (4 + 2.4)}%;
  height: 4%;
  width: ${props => props.widthAdd + 80}%;
  filter: drop-shadow(2px 2px 4px #68282680);
  
  opacity: ${props => props.transparent ? 0.35 : 1};
  transition: opacity 0.6s ease;
`;

const Shade = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100svh;
  background: #222222;
`;

const ShadeBand1 = styled.div`
  position: fixed;
  top: 0;
  left: -75%;
  width: 100%;
  height: 100svh;
  background: #222222;
  opacity: 55%;
  ${props => props.theme.breakpoints.down("sm")} {
    display: none;
  }
`;
const ShadeBand2 = styled.div`
  position: fixed;
  top: 0;
  left: 75%;
  width: 100%;
  height: 100svh;
  background: #222222;
  opacity: 55%;

  ${props => props.theme.breakpoints.down("sm")} {
    display: none;
  }
`;

const PublicPageContainer = styled.div`
  width: 100%;
  min-height: 100svh;
`;

const PrimaryPanelColumn = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: 100svh;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  & .panel {
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: center;
    gap: 12px;

    background: #222222b0;
    color: #ffffff;
    z-index: 2000;
    width: 400px;
    padding: 24px;
    box-sizing: border-box;
    border-radius: ${props => props.theme.shape.borderRadius}px;
    box-shadow: ${props => props.theme.shadows[6]};

    & h2 {
      font-size: 32px;
      margin-bottom: 10px;
      padding-bottom: 10px;
    }

    & legend > span, & label, & input {
      color: #ffffffa0;
    }

    & fieldset {
      border-color: #ffffffa0;
    }

    &:focus-within {
    }
  }

  ${props => props.theme.breakpoints.down("sm")} {
    padding: 12px;

    & .panel {
      width: 100%;
    }
  }
`;


export const PublicPageHarness = (props: PropsWithChildren<{}>) => {
  const widthAdd = useMemo(() => {
    return new Array(16).fill(0).map(x => Math.ceil(Math.random()*15));
  }, []);

  const [transparencies,setTransparencies] = useState<boolean[]>([]);
  useEffect(() => {
    const interval = setInterval(() => setTransparencies(widthAdd.map(() => Math.random() > 0.7)), 2000);
    return () => clearInterval(interval);
  }, [widthAdd]);

  return (
    <>
      <Shade />
      {widthAdd.map((w,i) => <RedStripX idx={i} widthAdd={w} transparent={transparencies[i] || false} src={`/img/header/header_${(i%5)+1}.jpg`} />)}
      <ShadeBand1 />
      <ShadeBand2 />
      <PublicPageContainer>

        <PrimaryPanelColumn>
          {props.children}
        </PrimaryPanelColumn>
      </PublicPageContainer>
    </>);
}

const LogoAndTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;

  & img {
    height: 3rem;
  }
  & h1 {
    font-size: 1rem;
  }
`;

export const PublicPageLogoTitle = (props: { subtitle: ReactNode }) => {
  return (
    <>
      <LogoAndTitle>
        <img src={LogoImg} alt="CMS" />
        <Typography variant="h1">
          CMS РИСА
        </Typography>
      </LogoAndTitle>
      {props.subtitle && <Typography variant="h2">{props.subtitle}</Typography>}
    </>
  )
}