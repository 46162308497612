import React, { ReactNode } from 'react';
import { IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';

import { OccupyFreeSpace } from './ActionRow';
import { Form, FormRow } from './Forms';
import { LoadingIndicator } from './LoadingIndicator';

import { ButtonOptionPicker } from './ButtonOptionPicker';
import { FieldDefinition, FieldSettingsPopupButton, FieldsSettings, TableForFields, TableProps } from '../schemed';
import { SearchField } from './SearchField';
import { Schema } from '../../hooks/useSchema';
import { CrudListData } from '../../api/useSimpleCrud';
import { PagingControl } from './PagingControl';
import { PagedLoadedData } from '../../hooks/useLoadedData';
import { useWindowHotkey } from './hotkeys';
import { FieldSorting } from '../../hooks/useFieldSorting';
import { TitleLink } from './Common.styles';

interface Props<T> {
    data: Omit<CrudListData<T>, "create"> & { create?: () => void };
    schema: Schema;
    title: ReactNode;
    titleLink?: string;
    noTitle?: boolean;
    headerActionsL?: ReactNode;
    headerActionsR?: ReactNode;
    tabs?: [string, string][];
    fields: FieldDefinition[];
    linkFields?: string[];
    getLinkValue?: (item: T) => string;
    totalRecordsText?: string;
    rowButtons?: (row: T) => ReactNode;
    onRowClick?: (row: T) => void;
    noSearchAutoFocus?: boolean;
    onSearchSubmit?: (visibleItems: T[]) => void;
    noNewItemHotkey?: boolean;
    fieldElement?: TableProps<T>["fieldElement"];
    fieldSetings?: FieldsSettings;
    sorting?: FieldSorting;
    noCreate?: boolean;
    tableProps?: Partial<TableProps<T>>;
    noFieldSettingsButton?: boolean;
    noSearchField?: boolean;
    noLoadingIndicator?: boolean;
}

export const SimpleList2 = <T extends unknown>(props: Props<T>) => {
    const { data, schema, title, tabs, fields, linkFields, getLinkValue, totalRecordsText, rowButtons, onRowClick } = props;

    useWindowHotkey("alt+n", () => props.noNewItemHotkey || !data.create ? null : data.create());
    
    return (
        <Form
          title={props.titleLink ? <TitleLink to={props.titleLink}>{title}</TitleLink> : title}
          noTitle={props.noTitle}
          headerItems={<>
              {!props.noCreate && data.create && <IconButton color='primary' size="small" onClick={() => data.create && data.create()}><Add /></IconButton>}
              {props.headerActionsL}
              <OccupyFreeSpace />
              {!props.noLoadingIndicator && data.isLoading && <LoadingIndicator sizeVariant="m" />}
              {!props.noSearchField && <SearchField
                  filter={data.filter}
                  setFilter={data.setFilter}
                  doSearch={() => props.onSearchSubmit && props.onSearchSubmit(data.data)}
                  noButton
                  autoFocus={!props.noSearchAutoFocus}
              />}
              {tabs && tabs.length > 0 && <ButtonOptionPicker
                  options={tabs}
                  selected={data.view}
                  setSelected={data.setView} />}
              {props.headerActionsR}
              {!props.noFieldSettingsButton && props.fieldSetings && <FieldSettingsPopupButton fieldsSettings={props.fieldSetings} />}
                  </>}>

            {!!(data as unknown as PagedLoadedData<T>).paging && <FormRow>
                <PagingControl data={(data as unknown as PagedLoadedData<T>)} totalRecordsText={totalRecordsText} />
            </FormRow>}

            <TableForFields
                {...(props.tableProps || {})}
                fields={props.fieldSetings ? props.fieldSetings?.activeFields : fields}
                fieldLink={field => linkFields && linkFields.find(f => f === field) ?  getLinkValue : null}
                data={data.data}
                schema={schema}
                rowButtons={rowButtons}
                onRowClick={onRowClick}
                fieldElement={props.fieldElement}
                sorting={props.sorting}
                />
            
        </Form>
    );
}