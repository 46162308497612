import React from "react";
import styled from '@emotion/styled';
import { FormattedMessage, useIntl } from "react-intl";
import { EditItemProps, useEditItem2 } from "../../../api/useNewItem";
import { FormGrid, LoadingIndicator, OptionsColorPicker, SimpleDialog } from "../../primitives";
import { useUserSettings } from "../../Settings/UserSettingsContext";
import { Filter } from "./typings";
import { generateCode } from "../../PowerDoc/plugins/common";
import { createSelectSchema, Schema } from "../../../hooks/useSchema";
import { FormControlsForFields } from "../FormControlsForFields";
import { Button, Chip, Typography } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useActionWithConfirmation } from "../../../api/useAction";

interface FiltersToSave {
  filters: Filter[];
  label: string;
  _id?: string;
  mode?: string;
  color?: string | null;
}

export const FiltersToSaveDefaults: FiltersToSave = {
  filters: [],
  mode: "user",
  label: "",
}

const FiltersToSaveSchema: Schema = {
  label: { label_id: "fieldsfilters.saved.label" },
  mode: createSelectSchema(
    [{ value: "user", label: "user" }, { value: "global", label: "global" }],
    { label_id: "fieldsfilters.saved.mode" }),
}

export const useSavedFilters = (settingsKey: string) => {
  const { formatMessage } = useIntl();
  const userSettings = useUserSettings();

  const saveFilters = useEditItem2<FiltersToSave>({
    save: (item) => {
      const realItem = { ...item, label: item.label || "Filters", _id: generateCode() };
      delete realItem.mode;
      if(item.mode === "global") {
        const withNewItem = [ ...(userSettings.global[settingsKey] || []), realItem];
        return userSettings.updateGlobal({ [settingsKey]: withNewItem })
        .then(() => realItem);
      } else {
        const withNewItem = [ ...(userSettings.user[settingsKey] || []), realItem];
        return userSettings.updateUser({ [settingsKey]: withNewItem })
          .then(() => realItem);
      }
    }
  });

  const editFilters = useEditItem2<FiltersToSave>({
    save: (item) => {
      const realItem = { ...item };
      delete realItem.mode;
      if(item.mode === "global") {
        const withNewItem = (userSettings.global[settingsKey] as FiltersToSave[] || []).map(f => f._id && f._id === realItem._id ? realItem : f);
        return userSettings.updateGlobal({ [settingsKey]: withNewItem })
        .then(() => realItem);
      } else {
        const withNewItem = (userSettings.user[settingsKey] as FiltersToSave[] || []).map(f => f._id && f._id === realItem._id ? realItem : f);
        return userSettings.updateUser({ [settingsKey]: withNewItem })
          .then(() => realItem);
      }
    }
  });

  const removeFilters = useActionWithConfirmation<void>(() => {
    if(editFilters.isEditing && editFilters.item?._id) {
      const item = editFilters.item;
      if(item.mode === "global") {
        const without = (userSettings.global[settingsKey] as FiltersToSave[] || []).filter(f => f._id !== item._id);
        return userSettings.updateGlobal({ [settingsKey]: without })
        .then(() => { editFilters.cancel() });
      } else {
        const without = (userSettings.user[settingsKey] as FiltersToSave[] || []).filter(f => f._id !== item._id);
        return userSettings.updateUser({ [settingsKey]: without })
          .then(() => { editFilters.cancel() });
      }
    } else {
      return Promise.resolve();
    }
  }, {
    title: formatMessage({ id: "fieldsfilters.saved.remove_title" }),
  })

  return {
    isAvailable: userSettings.isAvailable && !!settingsKey,
    canUpdateGlobal: userSettings.isAvailable && userSettings.canUpdateGlobal,
    saveFilters,
    editFilters,
    removeFilters,
    ensureLoaded: () => userSettings.ensureLoaded(),
    isLoading: userSettings.isLoadingUser || userSettings.isLoadingGlobal,
    user: (userSettings.user[settingsKey] || []) as FiltersToSave[],
    global: (userSettings.global[settingsKey] || []) as FiltersToSave[],
  }
}

type SaveFiltersData = ReturnType<typeof useSavedFilters>;

export const SaveFiltersDialog = (props: { data: EditItemProps<FiltersToSave>, isGlobalAvailable?: boolean }) => {
  const { data } = props;

  return (
    <SimpleDialog
      isOpen={data.isEditing}
      close={() => data.cancel()}
      dialogTitle={<FormattedMessage id="fieldsfilters.saved.dialog_title" />}
      save={() => data.save()}
      isSaving={data.isLoading}
      maxWidth="xs"
      fullWidth
      noFullscreen
      submitOnModEnter>
        {!!data.item &&
          <FormGrid columns="1fr">
            <FormControlsForFields
              data={data.item}
              onChange={(o,c) => data.update(c)}
              schema={FiltersToSaveSchema}
              fields={[
                ["label", { controlProps: { autoFocus: true }}],
                props.isGlobalAvailable ? ["mode", { labelIdPrefix: "fieldsfilters.saved.mode_label" }] : null,
              ]}
              errors={data.errors}
              />
            <OptionsColorPicker
              value={data.item.color}
              update={v => data.update({ color: v })}
              label={<FormattedMessage id="fieldsfilters.saved.color" />}
              />
          </FormGrid>}
    </SimpleDialog>
  )
}

export const EditSavedFiltersDialog = (props: { data: EditItemProps<FiltersToSave>, remove: () => void }) => {
  const { data } = props;

  return (
    <SimpleDialog
      isOpen={data.isEditing}
      close={() => data.cancel()}
      dialogTitle={<FormattedMessage id="fieldsfilters.saved.edit_dialog_title" />}
      save={() => data.save()}
      simpleExtraActions={<>
        <Button onClick={() => props.remove()}><FormattedMessage id="common.delete" /></Button>
      </>}
      isSaving={data.isLoading}
      maxWidth="xs"
      fullWidth
      noFullscreen
      submitOnModEnter>
        {!!data.item &&
          <FormGrid columns="1fr">
            <FormControlsForFields
              data={data.item}
              onChange={(o,c) => data.update(c)}
              schema={FiltersToSaveSchema}
              fields={[
                ["label", { controlProps: { autoFocus: true }}],
              ]}
              errors={data.errors}
              />
            <OptionsColorPicker
              value={data.item.color}
              update={v => data.update({ color: v })}
              label={<FormattedMessage id="fieldsfilters.saved.color" />}
              />
          </FormGrid>}
    </SimpleDialog>
  )
}

const ChipWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 0.5rem;
`;

interface FiltersInteraction {
  selectFilter: (f: FiltersToSave) => void;
  editFilter?: (f: FiltersToSave) => void;
}

const FiltersSelection = (props: { filters: FiltersToSave[] } & FiltersInteraction) => {
  const { filters, selectFilter, editFilter } = props;

  return !filters.length
    ? null
    : (
      <ChipWrapper>
        {filters.map(f => (
          <Chip
            size="small"
            style={f.color ? { background: f.color } : undefined}
            label={f.label}
            onClick={() => selectFilter(f)}
            deleteIcon={<Edit />}
            onDelete={editFilter ? () => editFilter(f) : undefined}
            />
        ))}
      </ChipWrapper>
    )
}

export const SavedFiltersSelection = (props: { data: SaveFiltersData } & Pick<FiltersInteraction, "selectFilter">) => {
  const { data } = props;
  if(!data.isAvailable) {
    return null;
  }

  return (
    <FormGrid columns="1fr" className="saved-filters-selection">
      {!!data.global.length && <>
        <Typography variant="caption" color="textSecondary">
          <FormattedMessage id="fieldsfilters.saved.global_title" />
        </Typography>
        <FiltersSelection
          filters={data.global}
          selectFilter={props.selectFilter}
          editFilter={data.canUpdateGlobal ? f => data.editFilters.startEditing({ ...f, mode: "global" }) : undefined} />
      </>}

      {!!data.user.length && <>
        <Typography variant="caption" color="textSecondary">
          <FormattedMessage id="fieldsfilters.saved.my_title" />
        </Typography>
        <FiltersSelection
          filters={data.user}
          selectFilter={props.selectFilter}
          editFilter={f => data.editFilters.startEditing({ ...f, mode: "user" })} />
      </>}

      {data.isLoading && <LoadingIndicator sizeVariant="s" color="primary" />}
    </FormGrid>)
}