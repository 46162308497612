import { IconButton, TextField } from '@mui/material';
import { KeyboardBackspace, SaveOutlined } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useTrackHistory } from '../../toolympus/components/HistoryFavorites';
import { OccupyFreeSpace, Form, FormGrid, SimpleDialog } from '../../toolympus/components/primitives';
import { Link } from '../../toolympus/components/primitives/Common.styles';
import { TabPanel, TabsHeader, useTabsState } from '../../toolympus/components/primitives/Tabs';
import { FieldDefinition, FormControlsForFields, TableForFields } from '../../toolympus/components/schemed';
import { NumberDisplay } from '../../toolympus/components/schemed/NumberEdit';
import { SimpleActions } from '../../toolympus/components/StatusAction';
import { FieldType } from '../../toolympus/hooks/useSchema';
import { OrderStatus } from './OrderStatus';
import { useEditOrder } from './useEditOrder';

interface Props {
    
}

export const OrderEditPage = (props: Props) => {
    const { id } = useParams<{ id: string }>();
    const data = useEditOrder(id);

    const { enqueueSnackbar } = useSnackbar();
    
    useTrackHistory(data.data.id ? `Заказ ${data.data.id}` : undefined);

    const tabs = useTabsState([
        ["products", "Товары"],
        ["details", "Детали"],
    ], "products", "tab");


    const controls = (fields: FieldDefinition[]) => (
        <FormControlsForFields
            data={data.data}
            schema={data.schema}
            onChange={(o,c) => data.update(c)}
            fields={fields.map(([f,opts]) => [f, opts || { readOnly: true }])}
        />
    )
    
    return (<>
        <Form
            title={<>
                <Link to="/orders">
                    <IconButton size="small"><KeyboardBackspace /></IconButton>
                </Link>
                Заказ
            </>}
            headerItems={<>
            <OccupyFreeSpace />
            <SimpleActions
                buttonProps={{ size: "small" }}
                actions={[
                    { action: data.sendMessage.startEditing, label: "отправить сообщение" },
                    data.refreshPaymentStatus.canRefresh && { action: data.refreshPaymentStatus.refresh, label: "обновить статус платежа" },
                    data.data.status !== "closed" && { action: data.closeOrder, label: "закрыть" },
                ]}
                />
            {data.hasChanges && <IconButton size="small" onClick={() => data.save()}><SaveOutlined /></IconButton>}
            <OrderStatus order={data.data} />
            </>}
            >
            <FormGrid columns="1fr 1fr 1fr 1fr">
                {controls([
                        ["id"],
                        ["email"],
                        ["phone"],
                        ["total_price"],
                        
                        ["delivery_type"],
                        ["payment_type"],
                        ["payment_ref"],
                        ["payment_amount"],
                    ])}
            </FormGrid>

            <TabsHeader state={tabs} />

            <TabPanel state={tabs.forTab("products")}>
                <TableForFields
                    data={data.data.products}
                    schema={data.detailsSchema}
                    fields={[
                        ["title"],
                        ["price"],
                        ["qty"],
                        ["color"],
                        ["size"],
                    ]}
                    fieldElement={f => {
                        switch(f) {
                            case "title":
                                return p => <Link to={`/products/${p.id}`}>{p.product.title}</Link>;
                            case "price":
                                return p => <NumberDisplay row={{ price: p.price ? +p.price : 0 }} field="price" schema={{ type: FieldType.decimal }} config={{ precision: 2 }} />;
                            case "color":
                                return p => (p.color && (p.product.colors || []).find(c => c.code === p.color)?.label) || p.color || "";
                            case "size":
                                return p => (p.size && (p.product.sizes || []).find(c => c.code === p.size)?.label) || p.size || "";
                        }
                    }}
                    />
            </TabPanel>

            <TabPanel state={tabs.forTab("details")}>
                <FormGrid columns="repeat(3,1fr)">
                    {controls([
                        ["lastname"],
                        ["firstname"],
                        ["company"],
                        
                        ["created_datetime", { utcToLocal: true, readOnly: true }],
                        ["payment_notification_datetime", { utcToLocal: true, readOnly: true }],
                        ["payment_link"],
                        ])}

                    {data.data.delivery_info && <FormControlsForFields
                        data={data.data.delivery_info}
                        schema={data.deliveryInfoSchema}
                        onChange={(o,c) => {}}
                        fields={[
                            ["country", { readOnly: true }],
                            ["city", { readOnly: true }],
                            ["address", { readOnly: true }],
                            ["comment", { readOnly: true, wrapperStyle: { gridColumn: "span 3"}}],
                        ]}
                    />}

                    {controls([
                        ["details", { wrapperStyle: { gridColumn: "span 3"}, hint: "Здесь можно оставлять внутренние комментарии"}],
                        ])}
                </FormGrid>
            </TabPanel>

        </Form>

        <SimpleDialog
            isOpen={data.sendMessage.isEditing}
            close={data.sendMessage.cancel}
            save={() => data.sendMessage.save().then(() => { enqueueSnackbar("Сообщение отправлено", { variant: "success", autoHideDuration: 5000 }); return {}; })}
            dialogTitle="Отправить сообщение"
            saveLabel="Отправить"
            >
            {data.sendMessage.item && <FormGrid columns="1fr">
                <TextField
                    value={data.sendMessage.item?.message || ""}
                    onChange={e => data.sendMessage.update({ message: e.target.value })}
                    multiline
                    label="Сообщение"
                    />
            </FormGrid>}
        </SimpleDialog>
    </>);
}
