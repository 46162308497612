import { IconButton, Typography } from '@mui/material';
import { SaveOutlined } from '@mui/icons-material';
import React from 'react';
import { OccupyFreeSpace } from '../../toolympus/components/primitives/ActionRow';
import { Form, FormGrid } from '../../toolympus/components/primitives/Forms';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { TabPanel, TabsHeader, useTabsState } from '../../toolympus/components/primitives/Tabs';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { DeliveryTypesForm } from './DeliveryTypesForm';
import { PaymentTypesForm } from './PaymentTypesForm';
import { useDeliveryTypesEdit } from './useDeliveryTypesEdit';
import { usePaymentTypesEdit } from './usePaymentTypesEdit';
import { useSettingsEdit } from './useSettingsEdit';

interface Props {
    
}

export const SettingsPage = (props: Props) => {
    const tabs = useTabsState([
        ["settings", "Общие"],
        ["delivery-types", "Способы доставки"],
        ["payment-types", "Способы оплаты"],
    ], "settings", "tab");

    const settings = useSettingsEdit();
    const paymentTypes = usePaymentTypesEdit();
    const deliveryTypes = useDeliveryTypesEdit();

    const isLoading = settings.isLoading || paymentTypes.isLoading || deliveryTypes.isLoading;
    const hasChanges = settings.hasChanges || paymentTypes.hasChanges || deliveryTypes.hasChanges;

    const save = () => {
        if(settings.hasChanges) {
            settings.save();
        }

        if(paymentTypes.hasChanges) {
            paymentTypes.save();
        }

        if(deliveryTypes.hasChanges) {
            deliveryTypes.save();
        }
    }

    return (
        <Form title="Настройки"
            headerItems={<>
                <OccupyFreeSpace />
                {isLoading && <LoadingIndicator />}
                {hasChanges && <IconButton size="small" onClick={save}><SaveOutlined /></IconButton>}
            </>}>
            <TabsHeader state={tabs} noMargin />

            <TabPanel state={tabs.forTab("settings")}>
                <FormGrid columns="1fr 1fr">
                    <FormControlsForFields
                        data={settings.data}
                        onChange={(o,c) => settings.update(c)}
                        schema={settings.schema}
                        fields={[
                            ["orders_allowed"],
                            ["self_notification_email"],
                            ["categories", { wrapperStyle: { gridColumn: "span 2"}}],
                        ]}
                        />
                </FormGrid>
                <FormGrid columns="1fr 1fr">
                    <Typography variant="h6" style={{ gridColumn: "span 2", marginBottom: 0, marginTop: "1rem" }}>СБП</Typography>
                    <FormControlsForFields
                        data={settings.data.sbp}
                        onChange={(o,c) => settings.update({ sbp: { ...settings.data.sbp, ...c }})}
                        schema={settings.sbpSchema}
                        fields={[
                            ["account_id"],
                            ["merchant_id"],
                            ["callback_url"],
                            ["payment_gateway_url"],
                        ]}
                        />
                </FormGrid>
            </TabPanel>

            <TabPanel state={tabs.forTab("delivery-types")}>
                <DeliveryTypesForm data={deliveryTypes} />
            </TabPanel>

            <TabPanel state={tabs.forTab("payment-types")}>
                <PaymentTypesForm data={paymentTypes} />
            </TabPanel>
        </Form>
    );
}
