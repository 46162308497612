import React from 'react';
import { VideocamOutlined, ViewModule } from "@mui/icons-material";
import { EditorPlugin } from "../../toolympus/components/PowerDoc";
import { generateCode } from '../../toolympus/components/PowerDoc/plugins/common';
import { TilesBlockElementType } from '../../toolympus/components/PowerDoc/plugins/Tiles/TilesBlockElement';
import { PanelBlockElementType } from '../../toolympus/components/PowerDoc/plugins/Panel/PanelBlockElement';
import { ParagraphElementType } from '../../toolympus/components/PowerDoc/slate/BlockElements';
import { CustomElement } from '../../slate';
import { ScriptElementType } from '../../toolympus/components/PowerDoc/plugins/Script';

const PartnersStylesInjected = `
<!-- на отображение логотипов влияют настройки внутренних панелей, блоков с тайлами и классы самих тайлов партнеров -->
<!-- чтобы задать конкретный размер тайлу, укажите его в CSS ниже (можно копировать) и проставьте соответствующее имя класса (xxxx) нужному партнеру  -->
<style>
.xxxx:nth-child(n) { width: 250px !important; }
// для малых экранов
@media(max-width:320px) { .xxxx:nth-child(n) { width: 150px !important; }}
</style>
`;

const createPartnerTilesBlock = (title?: string) => {
  return {
    type: TilesBlockElementType,
    title,
    tiles: [{ id: generateCode(), title: "Партнер" }],
    children: [{ text: "", }],
  };
}

const createEmbedBlock = (content: string) => ({
  type: ScriptElementType,
  children: [{ text: "" }],
  value: content,
});

const createPartnersBlock = () => {
  const panels = ["Партнеры", "Партнеры (стр2)"].map(title => createPartnerTilesBlock(title));

  return {
    type: PanelBlockElementType,
    content: { blocks: [
      ...panels,
      { type: ParagraphElementType, children: [{ text: "" }]},
      createEmbedBlock(PartnersStylesInjected),
    ]},
    children: [{ text: "", }],
    display_type: "custom-banner",
    carousel_mode: "new",
    custom_settings: ["display_type", "carousel_mode"],
    title: "Баннер партнеров",
  }
}


const FullwidthIframeScript = `
<!-- сюда script часть, если есть -->

<div class="fullwidth-iframe">
<!-- сюда iframe -->
<!-- width & height из iframe удалить, если указаны, - тогда блок растянется на ширину страницы -->
</div>
<br/>
<br/>
`;

export const ExtraCommandsPlugin: EditorPlugin = {
  key: "extra-commands-plugin",
  commands: [
    {
      name: "insert-partners-banner-block",
      invoke: (editor) => {
        editor.insertNode(createPartnersBlock() as CustomElement);
      },
      menu: { section: "insert-item", label: "Баннер партнеров", icon: <ViewModule /> },
    },
    {
      name: "insert-fullwidth-iframe",
      invoke: (editor) => {
        editor.insertNode(createEmbedBlock(FullwidthIframeScript) as CustomElement);
      },
      menu: { section: "insert-item", label: "Блок трансляции", icon: <VideocamOutlined /> },
    },
  ]
}