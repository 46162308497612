import { apiFetch } from "../../toolympus/api/core";
import { useCrudItem } from "../../toolympus/api/useSimpleCrud"
import { useLocalizedRecord } from "../../toolympus/components/LocalizedRecords/useLocalizedRecord";
import { useSchema } from "../../toolympus/hooks/useSchema";
import { Product } from "./typings";

export const useEditProduct = (id: string) => {
    const data = useCrudItem<Product>(`/api/settings/product/${id}`, {
        defaultValue: {
            id: 0,
            title: "",
            slug: "",
        }, 
    });

    const { product: schema } = useSchema();

    const localized = useLocalizedRecord(data.data, {
        defaultLocale: "ru",
        translatedFields: [
            "title",
            "description",
            "details",
            "extra_details",
        ],
        translationsField: "translations",
        updateItem: (o,c) => data.update(c),
    });

    const { locale, setLocale } = localized;

    const cloneProduct = () => {
        const cloned = { ...data.data, title: `${data.data.title} (copy)`, slug: `${data.data.slug}-copy` } as any;
        delete cloned.id;
        return apiFetch<Product>(`/api/settings/product`, "post", cloned);
    }

    const update = (c: Partial<Product>) => {
        const changes = { ...c };
        if(changes.is_free_downloadable) {
            changes.price = "0.0000";
        }
        return localized.updateItem(data.data, changes);
    }

    return {
        schema,
        ...data,
        data: localized.item,
        update,
        locale,
        setLocale,

        cloneProduct,
    }
}

export type EditProductData = ReturnType<typeof useEditProduct>;
