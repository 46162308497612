import styled from '@emotion/styled';
import { FormGrid } from '../../toolympus/components/primitives/Forms';

export const ItemWrapper = styled(FormGrid)`
    padding: 0.75rem 1rem 0.75rem 2rem;
    box-shadow: 0 0 5px 0 #00000040;
    position: relative;

    .caption {
        position: absolute;
        left: 0.5rem;
        top: 40px;
    }
`;
