import React from 'react';
import { Status } from '../../toolympus/components/StatusAction';
import { Order } from './typings';

interface Props {
    order: Pick<Order, "status">;
}

export const OrderStatus = (props: Props) => {
    return (
        <Status
            status={props.order.status}
            labels={{
                new: "Создан",
                paid: "Оплачен",
                closed: "Закрыт",
                payment_rejected: "Отказ платежа",
                payment_processing: "Платеж в обработке",
            }}
            hints={{
                new: { color: "#ddbb22"},
                paid: { color: "#00aacc"},
                closed: { color: "#00cc00"},
                payment_rejected: { color: "#cc0000"},
            }}

        />
    );
}
