import React, { ReactNode } from 'react';
import { LocaleSwitch } from "../toolympus/components/LocalizedRecords";

export const DefaultLanguage = "ru";
export const Languages = ["ru", "en"];

export const LanguagesWithLabels: [string, ReactNode][] = [
    ["ru", "ru"],
    ["en", "en"],
]

export const LanguageSwitch = (props: Omit<React.ComponentProps<typeof LocaleSwitch>, "availableLocales">) =>
    <LocaleSwitch availableLocales={LanguagesWithLabels} {...props} />;
