import React from 'react';
import { IconButton } from '@mui/material';
import { SaveOutlined } from '@mui/icons-material';
import { Form, FormGrid, LoadingIndicator, OccupyFreeSpace, TabPanel, TabsHeader, useSaveable, useTabsState } from '../../toolympus/components/primitives';
import { FormControlsForFields } from '../../toolympus/components/schemed';
import { useSiteSettingsEdit } from './useSiteSettingsEdit';
import { LanguageSwitch } from '../Localization';
import { BasicMenuEditor } from '../../toolympus/components/primitives/BasicMenuEditor';

export const SiteSettingsPage = () => {
    const data = useSiteSettingsEdit();

    useSaveable(data);

    const tabs = useTabsState([
        ["header_menu", "Меню в шапке"],
        ["menu", "Основное меню"],
    ], "header_menu", "tab");

    return (
        <Form title="Настройки"
            headerItems={<>
                <OccupyFreeSpace />
                {data.isLoading && <LoadingIndicator />}
                <LanguageSwitch locale={data.locale} setLocale={data.setLocale} />
                {data.hasChanges && <IconButton size="small" onClick={() => data.save()}><SaveOutlined /></IconButton>}
            </>}>
            <TabsHeader state={tabs} noMargin />

            <TabPanel state={tabs.forTab("header_menu")}>
              <BasicMenuEditor
                menu={(data.item?.header_menu?.primary || [])}
                update={v => data.update(data.item, { header_menu: { ...data.item.header_menu, primary: v }})}
                label="Первая строка"
                />

              <BasicMenuEditor
                menu={(data.item?.header_menu?.secondary || [])}
                update={v => data.update(data.item, { header_menu: { ...data.item.header_menu, secondary: v }})}
                label="Вторая строка"
                />
                
            </TabPanel>

            <TabPanel state={tabs.forTab("menu")}>
                <FormGrid columns="1fr">
                    <FormControlsForFields
                        data={data.item}
                        onChange={(o,c) => data.update(o,c)}
                        schema={data.schema}
                        fields={[
                            ["menu"],
                        ]}
                        />
                </FormGrid>
            </TabPanel>
        </Form>
    );
}
