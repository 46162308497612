import React from 'react';
import { OccupyFreeSpace } from '../../toolympus/components/primitives/ActionRow';
import { Form } from '../../toolympus/components/primitives/Forms';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { SearchField } from '../../toolympus/components/primitives/SearchField';
import { FormControl, TableForFields } from '../../toolympus/components/schemed';
import { FieldType } from '../../toolympus/hooks/useSchema';
import { OrderStatus } from './OrderStatus';
import { useOrderList } from './useOrderList';

interface Props {
    
}

export const OrderListPage = (props: Props) => {
    const data = useOrderList();

    return (<>
        <Form title="Заказы"
            headerItems={<>
                <OccupyFreeSpace />
                {data.isLoading && <LoadingIndicator sizeVariant="m" />}
                <FormControl
                    field="showClosed"
                    row={{ showClosed: data.showClosed }}
                    onChange={(o,c) => data.setShowClosed(c.showClosed)}
                    schema={{ type: FieldType.bool, label: "Показывать закрытые?"}}
                    extraProps={{ wrapperStyle: { alignSelf: "flex-end "}}}
                />
                <SearchField
                    filter={data.filter}
                    setFilter={data.setFilter}
                    doSearch={() => {}}
                    buttonLabel=" "
                    autoFocus
                />
                    </>}>

            <TableForFields
                fields={[
                    ["id"],
                    ["email"],
                    ["phone"],
                    ["lastname"],
                    ["firstname"],
                    ["created_datetime", { utcToLocal: true }],
                    ["total_price"],
                ]}
                fieldLink={field => ["id", "email"].find(f => f === field) ?  r => `/orders/${r.id}` : null}
                data={data.data}
                schema={data.schema}
                rowButtons={r => <OrderStatus order={r} />}
                />
            
        </Form>
    </>);
}
